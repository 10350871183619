import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import CTA from '@/components/brochureV2/layout/CTA/CTA';
import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';
import Eyebrow from '@/components/brochureV2/primitive/Eyebrow/Eyebrow';
import Section from '@/components/brochureV2/primitive/Section/Section';
import {H1Html, H2Html} from '@/components/shared/HtmlElements';
import IncentivesPromoBanner from '@/components/shared/IncentivesPromoBanner/IncentivesPromoBanner';
import {AlignmentEnum, ColorSchemeEnum, CtaVariant} from '@/enums';
import {conversionBackgroundByTheme} from '@/stylesheets/brochureV2/colorSchemes';
import type {CtaComponent} from '@/types';

export {CtaVariant};

export enum ConversionVariant {
  Primary = 'primary',
}

export interface ConversionProps {
  colorScheme?: ColorSchemeEnum;
  conversionVariant?: ConversionVariant;
  cta?: CtaComponent;
  heading?: string;
  kicker?: string;
  subhead?: string;
  className?: string;
  spacerOneClass?: string;
  spacerTwoClass?: string;
  headingClass?: string;
  subheadClass?: string;
  buttonClass?: string;
  containerClass?: string;
  eyebrowClass?: string;
  formClass?: string;
  inputWrapperClass?: string;
  sectionIndex?: number;
  sectionName?: string;
  displayPromoBanner?: boolean;
}

/**
 * @deprecated Please use `Conversion` from `@/components/base/sections/Conversion/Conversion.tsx`
 */
export default function Conversion({
  kicker,
  heading,
  subhead,
  colorScheme,
  conversionVariant,
  className,
  spacerOneClass,
  spacerTwoClass,
  headingClass,
  subheadClass,
  buttonClass,
  containerClass,
  eyebrowClass,
  formClass,
  inputWrapperClass,
  cta,
  displayPromoBanner,
  ...props
}: ConversionProps) {
  const currentScheme = colorScheme || ColorSchemeEnum.White;
  // NOTE: section indexes start at 1
  const conversionAsHero: boolean = props.sectionIndex === 1;

  const hClass = twMerge(headingClass, 'mb-sm');
  return (
    <Section
      {...props}
      className={twMerge(
        'relative text-center',
        [conversionBackgroundByTheme[currentScheme]],
        className,
      )}
    >
      <Spacer size="4xl" className={spacerOneClass} />
      <div className={cn(containerClass || 'container')}>
        {conversionAsHero && (
          <IncentivesPromoBanner mode="dark" className="pb-4" />
        )}
        {kicker && <Eyebrow className={eyebrowClass}>{kicker}</Eyebrow>}
        <AutoGrid className="gap-y-0 md:items-center" threshold={1}>
          {heading &&
            (conversionAsHero ? (
              <H1Html className={twMerge('font-bold text-t1', hClass)}>
                {heading}
              </H1Html>
            ) : (
              <H2Html className={twMerge('font-bold text-t2', hClass)}>
                {heading}
              </H2Html>
            ))}
          {subhead && (
            <div className="md:mx-24">
              <Spacer size="md" />
              <p className={twMerge('text-body-lg mb-xl', subheadClass)}>
                {subhead}
              </p>
            </div>
          )}
          {!subhead && cta?.ctaEmail && <Spacer size="sm" />}
          {!subhead && cta?.buttonPrimary && <Spacer size="md" />}
          {cta && (
            <CTA
              cta={cta}
              isFirstSection={conversionAsHero}
              alignment={AlignmentEnum.Center}
              colorScheme={colorScheme}
              formClass={formClass}
              inputWrapperClass={inputWrapperClass}
              buttonClass={buttonClass}
              displayPromoBanner={displayPromoBanner}
            />
          )}
        </AutoGrid>
      </div>
      <Spacer size="4xl" className={spacerTwoClass} />
    </Section>
  );
}
